'use client';

import ErrorPageLayout, { getMetadata } from '@/app/components/ErrorPage';
import { Button } from '@/app/components/ui/Button';

export async function generateMetadata() {
  return getMetadata('500_title');
}

export default function GlobalError({ reset }: { readonly reset: () => void }) {
  return (
    <ErrorPageLayout
      name="500"
      description="Something went wrong, please try again."
      headline="500"
      title="Internal Server Error"
    >
      <Button onClick={() => reset()}>Try again</Button>
    </ErrorPageLayout>
  );
}
