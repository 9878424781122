import React from 'react';

interface Props {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly name: string;
}

const PageLayout = ({
  children,
  name,
  className,
}: Props): React.JSX.Element => {
  return (
    <div className={className} data-testid={`page-${name}`}>
      {children}
    </div>
  );
};

export default PageLayout;
