import { cva, type VariantProps } from 'class-variance-authority';
import { type ElementType, forwardRef, type PropsWithChildren } from 'react';

import { cn } from '@/utils/ui';

const typographyVariants = cva('', {
  variants: {
    responsiveSize: {
      display: 'text-7.5xl leading-[90px]',
      h1: 'text-6xl leading-tight',
      h2: 'text-3.5xl leading-[42px] desktop:text-4.5xl desktop:leading-tight',
      h3: 'text-3.5xl leading-[42px]',
      h4: 'text-2xl leading-[38px]',
      h5: 'text-base leading-normal desktop:text-xl desktop:leading-normal',
      bodyLarge: 'text-base leading-normal',
      bodyMedium: 'text-sm leading-[20px]',
      p: 'text-sm leading-[20px]',
      bodySmall: 'text-xs leading-[16px]',
      span: '',
    },
    color: {
      gray: 'text-gray',
      'gray-25': 'text-gray-25',
      'gray-5': 'text-gray-5',
      'gray-50': 'text-gray-50',
      'gray-60': 'text-gray-60',
      'gray-80': 'text-gray-80',
      'green-success-25': 'text-green-success-25',
      'green-success-50-shade': 'text-green-success-50-shade',
      'helsinki-blue': 'text-helsinki-blue',
      'helsinki-blue-dark': 'text-helsinki-blue-dark',
      'helsinki-blue-5': 'text-helsinki-blue-5',
      'living-coral-25': 'text-living-coral-25',
    },
    decoration: {
      lineThrough: 'line-through',
      underline: 'underline',
    },
    fontFamily: {
      sans: 'font-sans',
      serif: 'font-serif',
    },
    italic: {
      true: 'italic',
    },
    leading: {
      normal: 'leading-normal',
      none: 'leading-none',
      tight: 'leading-tight',
      snug: 'leading-snug',
      relaxed: 'leading-relaxed',
      loose: 'leading-loose',
    },
    weight: {
      extraLight: 'font-extralight',
      light: 'font-light',
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
      extrabold: 'font-extrabold',
    },
  },
  defaultVariants: {
    color: 'gray',
    fontFamily: 'sans',
    responsiveSize: 'bodyMedium',
    weight: 'normal',
  },
  compoundVariants: [],
});

export type TypographyVariants = VariantProps<typeof typographyVariants>;

type Props<As extends ElementType> = TypographyVariants &
  PropsWithChildren<{
    readonly as?: As;
    readonly className?: string;
  }>;

export type SupportedElements = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span';

type TextElement = HTMLParagraphElement | HTMLHeadingElement;

const Typography = forwardRef<TextElement, Props<SupportedElements>>(
  (
    {
      as,
      children,
      className,
      color,
      decoration,
      fontFamily,
      italic,
      leading,
      responsiveSize,
      weight,
      ...rest
    },
    ref,
  ) => {
    const Element = as ?? 'p';
    return (
      <Element
        className={cn(
          typographyVariants({
            color,
            decoration,
            fontFamily,
            italic,
            leading,
            weight,
            responsiveSize: responsiveSize ?? as,
          }),
          className,
        )}
        ref={ref}
        {...rest}
      >
        {children}
      </Element>
    );
  },
);

Typography.displayName = 'Typography';

export { Typography, typographyVariants };
