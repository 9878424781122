import { env } from 'next-runtime-env';

const ouraEnv = env('NEXT_PUBLIC_OURA_ENV');

export const index = ouraEnv === 'production';
export const follow = ouraEnv === 'production';

export const robots = {
  robots: {
    index,
    follow,
  },
};

export const openGraph = {
  url: 'https://ouraring.com',
  siteName: 'Oura Ring',
  title: 'Oura Ring: Accurate Health Information Accessible to Everyone',
  description:
    'Oura Ring: the most accurate sleep and activity tracker is all about you: it measures the physiological signals of your body, understands your lifestyle, and guides you to make your own optimal daily choices. The ring features scientifically validated sleep tracking and personalized guidance.',
  images: {
    url: '/favicon/opengraph-image.png',
    alt: 'Oura Ring',
    width: 1080,
  },
};

const seoParams = {
  'best-buy': {
    title: 'Oura Ring Best Buy',
    description: 'Best Buy',
    openGraph: {
      ...openGraph,
      title: 'Oura Ring Best Buy',
      description: 'Best Buy',
    },
    ...robots,
  },
};

export default seoParams;
