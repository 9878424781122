import Image from 'next/image';

import SvgIcon from '@/app/components/VectorImage/svgs/SvgIcon';

import Icon from '../Icon';
import backgroundImage from './rings-background.webp';

interface BulkOrderBackgroundProps {
  readonly showImage?: boolean;
}

const BulkOrderBackground = ({ showImage }: BulkOrderBackgroundProps) => {
  return (
    <div className="absolute inset-0 z-[-1] min-h-[calc(100dvh_-_80px)] overflow-hidden bg-sandstone-light">
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className="responsive-grid container absolute inset-x-0 bottom-0">
        {showImage && (
          <div className="relative col-start-1 hidden h-dvh tablet:col-span-2 tablet:block desktop:col-span-3">
            <Icon
              icon="OURA_WORD_MARK"
              color="white"
              variant="glyph"
              className="absolute left-1/2 top-1/2 z-20 mx-auto -translate-x-1/2 -translate-y-1/2"
              width={189}
              height={60}
            />
            <div className="absolute z-10 size-full bg-rings-overlay" />
            <Image
              src={backgroundImage}
              alt="3 Oura rings on a beige background"
              sizes="33vw"
              fill
              priority
              className="object-cover"
            />
          </div>
        )}
        <div className="relative col-span-3 col-start-2 tablet:col-span-3 tablet:col-start-4 desktop:col-span-5 desktop:col-start-8">
          <SvgIcon title="Oura Ring Logo">
            <svg
              className="absolute bottom-[-126px] right-[-100px] fill-sandstone tablet:bottom-[-208px] tablet:right-0 desktop:bottom-[-268px]"
              viewBox="0 0 24 29"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.8978 5.13379C5.33714 5.13379 0 10.4702 0 17.0299C0 23.5897 5.33714 28.9267 11.8978 28.9267C18.4585 28.9267 23.7962 23.5897 23.7962 17.0299C23.7962 10.4702 18.4585 5.13379 11.8978 5.13379ZM11.8978 26.3603C6.75256 26.3603 2.56617 22.1751 2.56617 17.0299C2.56617 11.8854 6.75192 7.70026 11.8978 7.70026C17.043 7.70026 21.2294 11.8854 21.2294 17.0299C21.2294 22.1745 17.0437 26.3603 11.8978 26.3603Z" />
              <path d="M17.8447 0H5.94629V2.56647H17.8447V0Z" />
            </svg>
          </SvgIcon>
        </div>
      </div>
    </div>
  );
};

export default BulkOrderBackground;
