import { Typography } from '../ui/Typography';

interface ErrorPageContentProps {
  readonly description: string;
  readonly headline: string;
  readonly title: string;
}

export const ErrorPageContent = ({
  description,
  headline,
  title,
}: ErrorPageContentProps) => {
  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <div className="responsive-grid container">
      <div className="col-span-full flex flex-col gap-3 desktop:col-span-6 desktop:col-start-4">
        <Typography
          as="h1"
          responsiveSize="display"
          color="helsinki-blue"
          className="text-center"
        >
          {headline}
        </Typography>
        <Typography
          as="h2"
          responsiveSize="h2"
          color="helsinki-blue"
          className="text-center"
        >
          {title}
        </Typography>
        <Typography
          as="p"
          responsiveSize="bodyLarge"
          color="gray"
          className="pt-2 text-center"
          weight="extraLight"
        >
          {description}
        </Typography>
      </div>
    </div>
  );
};
