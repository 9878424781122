import Link from 'next/link';

import Icon from '../ui/Icon';

const ErrorPageNavigation = () => {
  return (
    <nav className="container h-[60px] py-4 desktop:h-[80px]" role="navigation">
      <ul className="flex h-full items-center gap-10">
        <li>
          <Link passHref href="/">
            <Icon
              icon="OURA_WORD_MARK"
              color="helsinki-blue-dark"
              height={29}
              width={93}
              variant="glyph"
            />
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default ErrorPageNavigation;
