import { cx } from 'class-variance-authority';
import localFont from 'next/font/local';
import { getTranslations } from 'next-intl/server';
import type { PropsWithChildren } from 'react';
import type { Messages } from 'types/locale-messages';

import { ErrorPageContent } from '@/app/components/ErrorPageContent';
import ErrorPageNavigation from '@/app/components/Navigation/ErrorPageNavigation';
import PageLayout from '@/app/components/PageLayout';
import BulkOrderBackground from '@/app/components/ui/BulkOrderBackground';
import { openGraph, robots } from '@/metadata/seoConfig';

const AkkuratLL = localFont({
  src: [
    {
      path: '../../fonts/AkkuratLL-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../fonts/AkkuratLL-Light.woff2',
      weight: '300',
      style: 'light',
    },
  ],
  variable: '--font-sans',
});

const EditorialNew = localFont({
  src: [
    {
      path: '../../fonts/PPEditorialNew-Ultralight.woff2',
      weight: '200',
      style: 'normal',
    },
    {
      path: '../../fonts/PPEditorialNew-UltralightItalic.woff2',
      weight: '200',
      style: 'italic',
    },
  ],
  variable: '--font-serif',
});

export async function getMetadata(titleKey: keyof Messages) {
  const t = await getTranslations();
  return {
    title: t(titleKey),
    openGraph: {
      ...openGraph,
      title: t(titleKey),
    },
    ...robots,
  };
}

type ErrorPageProps = PropsWithChildren<{
  readonly name: string;
  readonly description: string;
  readonly headline: string;
  readonly title: string;
}>;

export default function ErrorPage({
  name,
  children,
  ...props
}: ErrorPageProps) {
  return (
    <html
      className={cx(AkkuratLL.variable, EditorialNew.variable, 'nojs')}
      lang="en"
    >
      <body className={cx('tailwind')}>
        <ErrorPageNavigation />
        <PageLayout
          name={name}
          className="relative flex min-h-[calc(100dvh_-_60px)] w-full items-center justify-center desktop:min-h-[calc(100dvh_-_80px)]"
        >
          <ErrorPageContent {...props} />
          {children}
          <BulkOrderBackground />
        </PageLayout>
      </body>
    </html>
  );
}
