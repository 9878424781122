'use client';

import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { type ButtonHTMLAttributes, forwardRef, useState } from 'react';

import Icon from '@/app/components/ui/Icon';
import type { ICONS } from '@/app/consts/icons';
import type { TailwindThemeColor } from '@/app/types/tailwind';
import { cn } from '@/utils/ui';

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-full text-base font-medium text-helsinki-blue-dark ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-40',
  {
    variants: {
      variant: {
        primary: 'bg-enzo-blue hover:bg-helsinki-blue-dark hover:text-white',
        secondary: 'bg-white hover:bg-enzo-blue',
        outline:
          'border border-solid border-helsinki-blue-50 bg-white hover:bg-helsinki-blue-dark hover:text-white',
        ghost:
          'border border-solid border-white bg-transparent text-white hover:bg-white hover:text-helsinki-blue-dark',
        primaryLink:
          'rounded-none bg-transparent underline decoration-helsinki-blue-dark underline-offset-8 hover:decoration-enzo-blue',
        secondaryLink:
          'rounded-none bg-transparent text-white underline decoration-white underline-offset-8',
      },
      size: {
        sm: 'p-1.5 text-sm',
        md: 'px-6 py-3',
        lg: 'px-8 py-4',
        xl: 'px-8 py-5',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
    compoundVariants: [
      {
        variant: ['primaryLink', 'secondaryLink'],
        size: ['sm', 'md'],
        class: 'px-0 py-0.5',
      },
      {
        variant: ['primaryLink', 'secondaryLink'],
        size: ['lg', 'xl'],
        class: 'px-0 py-1',
      },
    ],
  },
);

type ButtonVariant = VariantProps<typeof buttonVariants>['variant'];

const WHITE_TEXT_VARIANTS: ButtonVariant[] = ['ghost', 'secondaryLink'];
const WHITE_TEXT_VARIANTS_WITH_HOVER: ButtonVariant[] = ['ghost'];
const BLACK_TEXT_VARIANTS_WITH_HOVER: ButtonVariant[] = ['primary', 'outline'];

type ButtonSize = VariantProps<typeof buttonVariants>['size'];

const getLoaderSize = (size: ButtonSize) => {
  switch (size) {
    case 'sm':
      return 16;
    case 'md':
      return 20;
    default:
      return 24;
  }
};

const getArrowSize = (size: ButtonSize) => {
  switch (size) {
    case 'sm':
    case 'md':
      return 20;
    default:
      return 24;
  }
};

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    readonly asChild?: boolean;
    readonly loading?: boolean;
    readonly rightArrow?: boolean;
    readonly leftArrow?: boolean;
    readonly icon?: keyof typeof ICONS;
  };

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading = false,
      rightArrow = false,
      leftArrow = false,
      icon,
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    const loaderSize = getLoaderSize(size);
    const arrowSize = getArrowSize(size);

    const [iconColor, setIconColor] = useState<TailwindThemeColor>(
      WHITE_TEXT_VARIANTS.includes(variant) ? 'white' : 'helsinki-blue-dark',
    );

    const handleMouseOver = () => {
      if (!variant || BLACK_TEXT_VARIANTS_WITH_HOVER.includes(variant)) {
        setIconColor('white');
      } else if (WHITE_TEXT_VARIANTS_WITH_HOVER.includes(variant)) {
        setIconColor('helsinki-blue-dark');
      }
    };

    const handleMouseLeave = () => {
      if (!variant || BLACK_TEXT_VARIANTS_WITH_HOVER.includes(variant)) {
        setIconColor('helsinki-blue-dark');
      } else if (WHITE_TEXT_VARIANTS_WITH_HOVER.includes(variant)) {
        setIconColor('white');
      }
    };

    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          loading && 'cursor-not-allowed',
        )}
        disabled={loading}
        ref={ref}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        {...props}
      >
        {icon && (
          <Icon
            icon={icon}
            color={iconColor}
            height={arrowSize}
            width={arrowSize}
          />
        )}
        {leftArrow && (
          <Icon
            icon="LEFT_ARROW_03"
            color={iconColor}
            height={arrowSize}
            width={arrowSize}
          />
        )}
        {loading && (
          <Icon
            icon="LOADER"
            className="animate-spin"
            color={iconColor}
            height={loaderSize}
            width={loaderSize}
          />
        )}
        <Slottable>{children}</Slottable>
        {rightArrow && (
          <Icon
            icon="RIGHT_ARROW_03"
            color={iconColor}
            height={arrowSize}
            width={arrowSize}
          />
        )}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
